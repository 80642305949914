<template>
  <div id="PerformancePanel">
    <feature-header name="Performance" :score="vehicle.Performance_Score" />
    <BasicFeaturePanel :features="features" icon-name="performance_icon.svg" />
  </div>
</template>
<script>
import common from "Mixins/common";
const FeatureHeader = () => import("Components/VehicleDetail/FeatureHeader");
const BasicFeaturePanel = () => import("Components/VehicleDetail/BasicFeaturePanel");
export default {
  props: {
    vehicle: Object,
  },
  computed: {
    features() {
      return [
        { label: "Horsepower", value: common.toNumber(this.vehicle.Horsepower) },
        { label: "Acceleration 0-60", value: `${this.vehicle.Acceleration} seconds` },
        { label: "Top speed", value: `${this.vehicle.Top_Speed} mph` },
        { label: "Drive type", value: this.vehicle.Drive_Type },
      ];
    },
  },
  components: {
    FeatureHeader,
    BasicFeaturePanel
  },
  mixins: [common],
};
</script>